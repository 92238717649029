

























import { defineComponent, computed, PropType } from '@vue/composition-api'
import { deliverySystemStatusColour as deliveryStatusColour } from '@/utils/utils'
import { IDeliveryFulfillment } from '@/interfaces'
import { Tooltip } from '@/components/common/Tooltip'

export default defineComponent({
  name: 'DeliveriesFulfillment',
  components: { Tooltip },
  props: {
    deliveries: {
      type: Object as PropType<IDeliveryFulfillment>,
      default: () => null,
    },
  },
  setup(props) {
    const totalDeliveries = computed(() =>
      Object.values(props.deliveries).reduce(
        (acc: number, item: number) => (acc += item),
        0
      )
    )
    return { deliveryStatusColour, totalDeliveries }
  },
})

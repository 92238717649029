



















import { defineComponent, PropType, computed } from '@vue/composition-api'
import { TooltipPlacement } from '@/enums'

export default defineComponent({
  name: 'Tooltip',
  props: {
    placement: {
      type: String as PropType<TooltipPlacement>,
      default: TooltipPlacement.TOP,
    },
    value: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { attrs, emit }) {
    const classes: string[] = ['tooltip-inner-wrapper']
    if (attrs.class) classes.push(attrs.class as string)

    const model = computed({
      get: () => props.value,
      set: (value) => {
        emit('update:prop', value)
      },
    })

    return { classes, model, TooltipPlacement }
  },
})

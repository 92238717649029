import VueI18n from 'vue-i18n'
import { IPaymentCondition } from '@/interfaces'

export const PaymentConditionMixin = {
  methods: {
    getPaymentCondition(paymentCondition: IPaymentCondition) {
      let condition: VueI18n.TranslateResult | string = ''

      if (paymentCondition) {
        if (paymentCondition.discountAmount) {
          condition += this.$t('organisation.paymentConditions.discount', {
            ...paymentCondition,
          })
        }
        condition += this.$t(
          'organisation.paymentConditions.paymentTargetInDays',
          { ...paymentCondition }
        )
      }

      return condition
    },
  },
}

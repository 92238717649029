import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { ICommonFetchQuery } from '@/interfaces'
import { IDeliveryManagement } from './composable'
import { FileAction } from '@/enums'
import { IDocumentOptions } from '@/utils/DocumentUtil/DocumentUtil'

export interface IFetchQuery extends ICommonFetchQuery {
  lastEvaluatedKey?: string
  offerType?: string
  searchInput?: string
  dateRange?: string
  materialTypeCode?: string
}

export const getDeliveryManagementDetails = async (
  organisationId: string,
  query?: IFetchQuery
): Promise<IDeliveryManagement> => {
  const response = await HttpService.request({
    query,
    path: `/delivery-tracker/deliveries/${organisationId}`,
  })

  return response as IDeliveryManagement
}

export const fetchCSVExportContents = async (
  organisationId: string,
  query?: IFetchQuery
): Promise<string> => {
  const response = await HttpService.request({
    query,
    path: `/delivery-tracker/deliveries/${organisationId}/csv-export`,
    responseType: 'text/csv',
  })
  return response as string
}

export const downloadPDF = async (
  filePath: string
): Promise<IDocumentOptions> => {
  const response = await HttpService.request({
    path: '/files/sign-request',
    method: HttpMethod.Post,
    body: {
      filePath,
      action: FileAction.Download,
    },
  })

  return {
    url: response.url,
  } as IDocumentOptions
}

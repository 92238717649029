





























































































































import enums from '@/enums'
import { defineComponent, computed, PropType } from '@vue/composition-api'
import { ITransaction } from '@/modules/transaction/resources'
import TransactionStatusBadge from '@/components/common/TransactionStatusBadge.vue'
import { DeliveriesFulfillment } from '@/components/common/DeliveriesFulfillment'
import { ShippingMixin } from '@/mixins/ShippingMixin'
import { PaymentConditionMixin } from '@/mixins/PaymentConditionMixin'
import { IDeliveryFulfillment } from '@/interfaces'
import { Tooltip } from '@/components/common/Tooltip'

export default defineComponent({
  name: 'TransactionMainInfo',
  components: {
    TransactionStatusBadge,
    DeliveriesFulfillment,
    Tooltip,
  },
  mixins: [ShippingMixin, PaymentConditionMixin],
  props: {
    transaction: {
      type: Object as PropType<ITransaction>,
      default: () => null,
    },
    deliveries: {
      type: Object as PropType<IDeliveryFulfillment>,
      default: () => null,
    },
    listView: {
      type: Boolean as PropType<Boolean>,
      default: () => false,
    },
  },
  setup(props, { root }) {
    const router = root.$options.router
    const offer = computed(() => props.transaction.offer || {})
    const offerStation = computed(
      () =>
        offer.value?.sourceStation ||
        offer.value?.destinationStation ||
        props.transaction?.partner?.station ||
        {}
    )
    const partner = computed(
      () =>
        props.transaction.partner ||
        [props.transaction.buyer, props.transaction.seller].find(
          (user) => user.organisation.id !== props.transaction.organisationId
        )
    )
    const isContractTypeRecurring = computed(
      () => offer.value.contractType === enums.OfferContractType.Recurring
    )

    const onOfferDetailes = async (id: string) => {
      await router?.push({ name: 'offer', params: { id } })
    }

    return {
      offer,
      offerStation,
      partner,
      isContractTypeRecurring,
      onOfferDetailes,
      enums,
    }
  },
})
